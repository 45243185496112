<div cdkOverlayOrigin #rightSidebar="cdkOverlayOrigin"></div>

<ng-template
	cdkConnectedOverlay
	[cdkConnectedOverlayOrigin]="rightSidebar"
	[cdkConnectedOverlayOpen]="isOpen()"
>
	<right-sidebar-overlay>
		<router-outlet name="right-sidebar" />
	</right-sidebar-overlay>
</ng-template>

@if (showVersionPopup()) {
	<div class="center-bottom">
		<mat-card>
			<mat-card-header class="version-check-header">
				<mat-card-title>New version available</mat-card-title>
				<button (click)="close()" mat-icon-button>
					<mat-icon class="primary-grey" svgIcon="remove"></mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content>
				Please save your work and reload the page to start using the latest version.
			</mat-card-content>
			<mat-card-actions align="end">
				<button (click)="reload()" mat-flat-button>Reload</button>
			</mat-card-actions>
		</mat-card>
	</div>
}

<div
	class="right-sidebar-container"
	[class.fullscreen]="isFullscreenMode"
	*ngrxLet="sidebarConfig$ as sidebarConfig"
>
	<div class="right-sidebar-header">
		<div class="sidebar-nav-title">
			<div
				class="sidebar-nav-bar"
				*ngIf="sidebarService.isHistoryExisting && !sidebarConfig.attachmentMenu"
			>
				<mat-icon
					class="home"
					[class.active]="sidebarService.currentPageIndex !== 0"
					svgIcon="home-nav"
					(click)="navigateToHome()"
				></mat-icon>
				<mat-icon
					class="backward"
					[class.active]="sidebarService.isPreviousComponent"
					svgIcon="nav_arrow_backward"
					(click)="navigateToPrevious()"
				></mat-icon>
				<mat-icon
					class="forward"
					svgIcon="nav_arrow_forward"
					[class.active]="sidebarService.isNextComponent"
					(click)="navigateToFirst()"
				></mat-icon>
			</div>
			<div class="sidebar-header-title">
				<mat-icon *ngIf="!sidebarConfig.noIcon" [svgIcon]="sidebarConfig.icon"></mat-icon>
				<span class="uppercase">{{ sidebarConfig.title }}</span>
			</div>
		</div>

		<div class="sidebar-view-actions">
			<mat-icon
				class="fullscreen-icon"
				*ngIf="!isFullscreenMode && sidebarConfig.allowFullscreen"
				(click)="fullscreen()"
				>open_in_full</mat-icon
			>
			<mat-icon
				class="fullscreen-icon"
				*ngIf="isFullscreenMode"
				(click)="exitFullscreen()"
				>close_fullscreen</mat-icon
			>
			<mat-icon (click)="minimize()">minimize</mat-icon>
			<mat-icon class="white-icon" (click)="close()">close</mat-icon>
		</div>
	</div>
	<div
		class="right-sidebar-content with-actions"
		[ngClass]="{
			'nice-scroll visible-scroll colored-scroll-track':
				!sidebarConfig.disableDefaultScroll,
		}"
	>
		<ng-template [cdkPortalOutlet]="sidebarService.sidebarPortal$ | async"></ng-template>
	</div>

	@if (sidebarConfig.showContentActions) {
		<div class="sidebar-content-actions">
			<au-techno-btn (click)="cancel()">Cancel</au-techno-btn>
			<button
				matRipple
				class="au-btn m-l-sm uppercase"
				[disabled]="disableButton"
				(click)="actionClicked()"
			>
				{{ sidebarConfig.actionBtnName }}
			</button>
		</div>
	}
</div>

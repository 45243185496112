import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	signal,
	WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { unreadNotificationsCount } from '@app/core/notifications/state/notifications.selectors';
import { Store } from '@ngrx/store';

@Component({
	selector: 'menu-counter',
	imports: [CommonModule],
	templateUrl: './menu-counter.component.html',
	styleUrl: './menu-counter.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuCounterComponent {
	private readonly destroyRef = inject(DestroyRef);
	private readonly store$ = inject(Store);
	count: WritableSignal<number> = signal(0);
	unreadNotificationsCount$ = this.store$
		.select(unreadNotificationsCount)
		.pipe(takeUntilDestroyed(this.destroyRef))
		.subscribe(count => {
			this.count.set(count);
		});
}

import { Routes } from '@angular/router';
import { AiChatComponent } from '@app/ai-chat/ai-chat.component';
import { FilesEffects } from '@app/pages/cmms/components/files/state/files.effects';
import { provideEffects } from '@ngrx/effects';
import { RightSidebarRoutes } from './types';

export const RIGHT_SIDEBAR_ROUTES: Routes = [
	{
		path: RightSidebarRoutes.AiChat,
		component: AiChatComponent,
		outlet: 'right-sidebar',
		providers: [provideEffects([FilesEffects])],
	},
];

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { ChatListComponent } from '@app/ai-chat/chat-list/chat-list.component';
import { ChatConversationAreaComponent } from '@app/ai-chat/chat-conversation-area/chat-conversation-area.component';
import { AiChatService } from '@app/ai-chat/services/ai-chat.service';

@Component({
	selector: 'ai-chat',
	imports: [CommonModule, ChatListComponent, ChatConversationAreaComponent],
	templateUrl: './ai-chat.component.html',
	styleUrls: ['./ai-chat.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [AiChatService],
})
export class AiChatComponent {
	isFullscreen$: Observable<boolean>;
}

import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuUserExtraFeaturesService } from '@core/services/au-user-extra-features.service';
import { isAiChatOpened } from '@app/layout/state/layout.selectors';
import { NewRightSidebarService } from '@app/layout/new-right-sidebar/services/new-right-sidebar/new-right-sidebar.service';
import { RightSidebarRoutes } from '@app/layout/new-right-sidebar/types';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'topbar-action-buttons',
	templateUrl: './topbar-action-buttons.component.html',
	styleUrls: ['./topbar-action-buttons.component.scss'],
	imports: [MatIconModule, CommonModule],
})
export class TopbarActionButtonsComponent {
	private readonly store$ = inject(Store);
	private readonly userExtraFeaturesService = inject(AuUserExtraFeaturesService);
	private readonly newRightSidebarService = inject(NewRightSidebarService);

	isAiChatEnabled = this.userExtraFeaturesService.isAiChatEnabled;

	isAiChatOpened = this.store$.selectSignal(isAiChatOpened);

	openAiChat() {
		this.newRightSidebarService.toggleAndNavigate(RightSidebarRoutes.AiChat);
	}
}

import { OverlayModule } from '@angular/cdk/overlay';
import { Component, inject } from '@angular/core';
import { NewRightSidebarService } from '../../services/new-right-sidebar/new-right-sidebar.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { RightSidebarOverlayComponent } from '../right-sidebar-overlay/right-sidebar-overlay.component';

@Component({
	selector: 'app-right-sidebar-container',
	templateUrl: './right-sidebar-container.component.html',
	styleUrls: ['./right-sidebar-container.component.scss'],
	imports: [OverlayModule, RightSidebarOverlayComponent, RouterOutlet],
})
export class RightSidebarContainerComponent {
	private readonly sidebar = inject(NewRightSidebarService);

	isOpen = toSignal(this.sidebar.isOpenState$);

	constructor() {}
}

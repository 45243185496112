import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	getUnreadNotificationsCount,
	loadMoreNotifications,
	loadMoreNotificationsFailure,
	loadMoreNotificationsSuccess,
	loadNotifications,
	loadNotificationsFailure,
	loadNotificationsSuccess,
	mapNotifications,
	markAllNotificationAsRead,
	markNotificationAsRead,
	notificationsLoaded,
	setUnreadNotificationsCount,
	updateNotificationWithReadStatus,
} from '@core/notifications/state/notifications.actions';
import { EMPTY, mergeMap, of } from 'rxjs';
import { catchError, map, take, withLatestFrom } from 'rxjs/operators';
import { AuErrorAdapter } from '@app/shared';
import { AuNotificationsService } from '@core/services/au-notifications.service';
import { INotificationsState } from '@core/notifications/state/notifications.reducer';
import { notificationsPagination } from '@core/notifications/state/notifications.selectors';

@Injectable()
export class NotificationsEffects {
	private readonly $actions = inject(Actions);
	private readonly notificationsService = inject(AuNotificationsService);
	private readonly store$ = inject(Store<INotificationsState>);

	loadNotifications$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(loadNotifications),
			mergeMap(() => {
				//after refactoring sidebar, we can change this to only load 10 at the time. But we need to load all
				//Because we have different functions for opening each entity, so we need to check that the entity matches the notification and for that we need all notifications immideatly.
				return this.notificationsService.loadNotifications({}).pipe(
					map(({ notifications, notificationsPagination }) => {
						return loadNotificationsSuccess({
							notifications,
							notificationsPagination,
						});
					}),
					catchError(error => {
						AuErrorAdapter.printGqlErrorToConsole(error);
						return of(loadNotificationsFailure());
					})
				);
			})
		);
	});

	loadNotificationsSuccess$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(loadNotificationsSuccess),
			map(({ notifications, notificationsPagination }) => {
				return notificationsLoaded({
					notifications,
					notificationsPagination,
				});
			})
		);
	});

	loadMoreNotifications$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(loadMoreNotifications),
			withLatestFrom(this.store$.select(notificationsPagination)),
			mergeMap(([_, pagination]) => {
				if (!pagination.pageInfo.hasNextPage) {
					return EMPTY;
				}

				return this.notificationsService.loadMoreNotifications(pagination.pageInfo).pipe(
					map(({ notifications, notificationsPagination }) => {
						return loadMoreNotificationsSuccess({
							notifications,
							notificationsPagination,
						});
					}),
					catchError(error => {
						AuErrorAdapter.printGqlErrorToConsole(error);
						return of(loadMoreNotificationsFailure());
					})
				);
			})
		);
	});

	markNotificationAsRead$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(markNotificationAsRead),
			mergeMap(({ pk }) => {
				return this.notificationsService.markNotificationAsRead(pk).pipe(
					map(() => {
						return updateNotificationWithReadStatus({
							notification: {
								// this is id of entity adapter!!! this code is correct;
								id: pk,
								changes: { isRead: true },
							},
						});
					}),
					catchError(error => {
						AuErrorAdapter.printGqlErrorToConsole(error);
						return EMPTY;
					})
				);
			})
		);
	});

	markAllNotificationAsRead$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(markAllNotificationAsRead),
			mergeMap(() => {
				return this.notificationsService.markAllNotificationsAsRead().pipe(
					map(() => {
						return mapNotifications({
							mapper: notification => ({ ...notification, isRead: true }),
						});
					}),
					catchError(error => {
						AuErrorAdapter.printGqlErrorToConsole(error);
						return EMPTY;
					})
				);
			})
		);
	});

	getUnreadNotificationsCount$ = createEffect(() => {
		return this.$actions.pipe(
			ofType(getUnreadNotificationsCount),
			mergeMap(() => {
				return this.notificationsService.getUnreadNotifications().pipe(
					take(1),
					map((unreadCount: number) => {
						return setUnreadNotificationsCount({ count: unreadCount });
					}),
					catchError(error => {
						AuErrorAdapter.printGqlErrorToConsole(error);
						return EMPTY;
					})
				);
			})
		);
	});
}

<mat-drawer-container fullscreen [hasBackdrop]="false">
	<mat-drawer
		class="right-sidebar"
		mode="over"
		position="end"
		#rightSidebar
		[autoFocus]="false"
	>
		<au-right-sidebar></au-right-sidebar>
	</mat-drawer>
	<mat-drawer-content>
		<div class="application-wrapper">
			<au-nav-sidebar></au-nav-sidebar>

			<div class="content-wrapper">
				<div id="scroll-to-top-anchor"></div>
				<app-header></app-header>

				<div
					class="layout-container nice-scroll colored-scroll-track visible-scroll"
					#mainContent
				>
					<!--Uncomment below component to play with data from indexedDB-->
					<!--<database-playground></database-playground>-->
					<router-outlet></router-outlet>
					<app-right-sidebar-container />
				</div>
			</div>
		</div>
		<button
			*ngIf="sidebarService.sidebarMinimized$ | async"
			class="right-sidebar-toggle-button"
			mat-fab
			(click)="undoMinimize()"
		>
			<mat-icon svgIcon="extension"></mat-icon>
		</button>
	</mat-drawer-content>
</mat-drawer-container>

<au-privacy-policy
	*ngIf="showPolicyBar"
	(policyAccepted)="privacyPolicyAccepted()"
></au-privacy-policy>
<version-check />
<ng-template #appModal></ng-template>

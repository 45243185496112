<div class="navbar-logo">
	<a routerLink="/">
		<img class="logo" [src]="logoImageUrl" alt="Autility logo" (click)="onLogoClick()" />
	</a>
	<div class="navbar-profile-actions">
		<div
			class="profile-icon cursor-pointer"
			[matMenuTriggerFor]="profileMenu"
			#trigger="matMenuTrigger"
			[class.active]="trigger.menuOpen"
		>
			<au-round-avatar [data]="profile$ | async" [size]="24" />
		</div>
		<button mat-icon-button class="settings" routerLink="/settings/organization/about">
			<mat-icon svgIcon="gear"> </mat-icon>
		</button>
	</div>
</div>

<mat-menu #profileMenu="matMenu" class="profile-actions" xPosition="after">
	<button mat-menu-item routerLink="/settings/profile/edit">
		<mat-icon class="secondary-text-icon" svgIcon="user"></mat-icon>
		Edit Profile
	</button>

	<button mat-menu-item (click)="onLogout()" aria-hidden="true">
		<mat-icon class="secondary-text-icon" svgIcon="sign_out"></mat-icon>
		Logout
	</button>
</mat-menu>

<div *ngIf="halloweenShow" class="easter-egg-container">
	<img src="./assets/icons/logo/halloween/bats.gif" />
</div>

@if (xmassShow) {
	<div class="easter-egg-container xmass">
		<img [src]="xmassGif" />
	</div>

	<xmass-lights-animation class="lights-animations"></xmass-lights-animation>
}
<xmass-snow *ngIf="dateIsNearTo === 'xmass'"></xmass-snow>

<!--You can add more celebration containers with gifs here-->

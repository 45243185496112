import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RightSidebarRoutes } from '../../types';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NewRightSidebarService {
	private readonly router = inject(Router);
	isOpenState$ = new BehaviorSubject<boolean>(false);
	gracefulClose$ = new Subject<void>();

	constructor() {}

	isOpen(): boolean {
		return this.isOpenState$.value;
	}

	openAndNavigate(route: RightSidebarRoutes) {
		this.router.navigate([{ outlets: { 'right-sidebar': [route] } }]);
		if (this.isOpen()) return;
		this.open();
	}

	toggleAndNavigate(route: RightSidebarRoutes) {
		if (!this.isOpen()) {
			this.router.navigate([{ outlets: { 'right-sidebar': [route] } }]);
		}
		this.toggle();
	}

	toggle() {
		if (this.isOpen()) {
			this.close();
			return;
		}
		this.open();
	}

	open() {
		this.isOpenState$.next(true);
	}

	close() {
		this.gracefulClose$.next();
	}
}
